.width-modal-openAccountBonus {
  min-width: 420px;
  max-width: 420px;
  @media (max-width: 480px) {
    min-width: 363px;
    max-width: 363px;
    top: 0;
    margin: auto;
  }
}
.width-modal-alert-openAccount {
  min-width: 348px;
  max-width: 348px;
  @media (max-width: 480px) {
    min-width: 348px;
    max-width: 348px;
    top: 0;
    margin: auto;
  }
}
.width-modal-export {
  min-width: 608px;
  max-width: 608px;
  @media (max-width: 480px) {
    min-width: 363px;
    max-width: 363px;
    top: 0;
    margin: auto;
  }
}
.width-modal-openAccount {
  min-width: 484px;
  max-width: 484px;
  @media (max-width: 480px) {
    min-width: 363px;
    max-width: 363px;
    top: 0;
    margin: auto;
  }
}
.width-modal-swap {
  min-width: 420px;
  max-width: 420px;
  @media (max-width: 480px) {
    min-width: 363px;
    max-width: 363px;
    top: 0;
    margin: auto;
  }
  &.ant-modal .ant-modal-footer {
    padding: 0 !important;
  }
}
.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 20px !important;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
  pointer-events: auto;
  width: auto;
  @media (max-width: 767px) {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 2px;
    -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: auto;
    width: auto;
    max-height: 80vh;
    overflow: auto;
  }
}
.w-modal-swap .ant-modal-content {
  width: 500px;
  @media (max-width: 480px) {
    width: auto;
  }
}
.w-modal-Verify .ant-modal-content {
  width: 406px;
  padding: 24px;
  @media (max-width: 480px) {
    width: auto;
  }
}
.w-modal-campaign .ant-modal-content {
  width: 420px;
  padding: 24px;

  background: url("../../../public/assets/images/campaigns/bg-modal-campaign.png")
    no-repeat;
  background-color: #0d0b2a;
  @media (max-width: 480px) {
    width: auto;
  }
}
// .bg-seminar {
//   background: url("../images/bg-seminar.png") no-repeat;
// }
