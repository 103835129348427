.ant-card-body {
  padding: 14px !important;
}

.ant-radio-inner {
  border-color: rgba(0, 0, 0, 0.65);
}

/* .ant-input-affix-wrapper-focused {
  border: 1px solid #1f7bb8 !important;
} */

/* .login.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #1f7bb8 !important;
} */
.login.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  /* border: 1px solid #1f7bb8 !important; */
}

.login.ant-select:not(.ant-select-disabled):focus .ant-select-selector {
  /* border: 1px solid #1f7bb8 !important; */
}

.login.ant-input:focus {
  /* border: 1px solid #1f7bb8 !important; */
}

.login.ant-input:hover {
  /* border: 1px solid #1f7bb8 !important; */
}

i.anticon {
  vertical-align: 0.1rem;
}

.w-90 {
  width: 60%;
}

.registerlogo {
  width: auto;
  height: 70px;
  border-radius: 6px;
}

.logo-footer {
  width: 48px;
  height: 48px;
  border-radius: 6px;
}

.logo-text-footer {
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
}

@media (max-width: 390px) {
  .logo-footer {
    width: 24px;
    height: 24px;
  }
}

@media (min-width: 391px) and (max-width: 834px) {
  .logo-footer {
    width: 32px;
    height: 32px;
  }
  .logo-text-footer {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }
}

#footer-warning {
  display: block;
}

.style-icon {
  background-color: #5b5b5b;
  color: white !important;
  font-size: 20px;
  border-radius: 33px;
}

.ant-message {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum", "tnum";
  font-feature-settings: "tnum", "tnum";
  position: fixed;
  top: 650px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}

@media (max-width: 425px) {
  .w-90 {
    width: 70%;
  }

  #footer-warning {
    display: block;
    text-align: left;
  }

  .ant-layout-footer {
    padding: 24px 16px !important;
  }

  .ant-modal-wrap {
    display: flex;
    flex-direction: column;
  }
}
