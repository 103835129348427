body {
    margin: 0;
    font-family: "Inter" !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#app {
    background: transparent !important;
}

.important-za-component-style {
    background: rgb(26, 26, 26);
    border-radius: 16px;
    padding: 16px 8px;
    margin-top: 42px;
    line-height: 16.39px;
}

.important-za-component-style > span {
    font-size: 12px;
    font-weight: 400;
}