@import "./variable";
.radio-custom-payment {
  height: 100%;
  display: flex;
  width: 100%;
  border: 1px solid $badge-disabled;
  line-height: unset;
  border-radius: 12px;
  padding: 16px;

  &.ant-radio-wrapper .ant-radio {
    align-self: flex-start;
  }
  &.ant-radio-wrapper .ant-radio-inner {
    top: 4px;
  }
  &.ant-radio-wrapper .ant-radio-input {
    top: 4px;
  }
  &.ant-radio-wrapper .ant-radio-checked::after {
    top: 4px;
  }
  &.checked {
    border: 1px solid $primary-sub-color;
    background-color: #1cb89408;
  }
  &:hover {
    border: 1px solid $primary-sub-color;
  }
  &.ant-radio-wrapper-disabled {
    border: 1px solid #d9d9d9;
    background-color: #f9f9f9;
  }
}
.bage-payment {
  &.recommended {
    background: #009eff33;
    padding: 4px 12px 4px 12px;
    border-radius: 12px;
  }
  &.other {
    width: fit-content;
    background: #99999933;
    padding: 4px 12px 4px 12px;
    border-radius: 12px;
    &:hover {
      background: #99999966;
    }
    &.active {
      background: #99999966;
    }
  }
}
.box-payment-method {
  border-radius: 12px;
  border: 1px solid $badge-disabled;
  background: rgba(217, 217, 217, 0.2);
  padding: 16px;
}
.box-payment-receive {
  border-radius: 12px 12px 0 0;
  border: 1px solid $badge-disabled;
  background: rgba(217, 217, 217, 0.2);
  padding: 16px;
}
.box-payment-resultamount {
  border-radius: 0 0 12px 12px;
  border: 1px solid $badge-disabled;
  background: rgba(217, 217, 217, 0.2);
  padding: 16px;
  border-top: none;
}
.payment {
  &.ant-form-item .ant-form-item-explain-error {
    margin-bottom: 8px;
  }
}
.container-scan-qrcode {
  border-radius: 20px;
  border: 1px solid var(--line, #d9d9d9);
  padding: 16px 0;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.box-scan-qrcode {
  .head-qrcode {
    border-radius: 14px 14px 0px 0px;
    background: $divider-dark;
  }
  .detail-qrcode {
    position: relative;
    border-radius: 14px;
    border: 1px solid $divider-dark;
    // border-bottom: 1px solid $divider-dark;
    // border-left: 1px solid $divider-dark;
    overflow: hidden;
  }
}
.logo-qr-iux {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.box-faq {
  border-radius: 12px;
  background: #f9f9f9;
  padding: 24px 20px;
}

.container-withdraw-otp {
  border-radius: 12px;
  border: 1px solid #d9d9d9;
}
.box-payment-detail {
  padding: 16px 0px 16px 24px;
  @media screen and (max-width: 768px) {
    padding: 16px;
  }
}
.box-payment-fund-detail {
  padding: 16px 0px 16px 0px;
  @media screen and (max-width: 768px) {
    padding: 16px;
  }
}
.box-withdraw-otp-amount {
  padding: 16px 24px;
}
.box-payment-slip {
  background: $bg-gray;
  width: 362px;
  padding: 30px 16px 15px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  .box-slip-iux {
    position: relative;
    margin-top: 30px;
    padding: 24px 20px;
    background: #fff;
    height: 500px;

    &::after {
      position: absolute;
      content: "";
      left: 12px;
      top: 0;
      bottom: -6px;
      width: 100%;
      border-bottom: 12px dotted #f2f2f2;
    }
    // &::before {
    //   content: " ";
    //   display: block;
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   width: 100%;
    //   height: 100%;
    //   opacity: 0.1;
    //   //   background-image: url("http://localhost:3000/assets/images/payment/slip/watermark.png");
    //   background-image: url("https://www.iuxmarkets.com/assets/images/icons/iux-logo.png");
    //   background-repeat: no-repeat;
    //   background-position: 50% 50%;
    //   background-size: 200px;
    // }
  }
}
.demo-bg {
  opacity: 0.1;
  position: absolute;
  top: 50%;
  width: 200px;
  height: auto;
  left: 50%;
  transform: translate(-50%, -50%);
}
.box-select-payment {
  cursor: pointer;
  &:hover {
    border: 1px solid $primary-sub-color;
    background-color: #1cb89408;
  }
}
.withdraw-otp .ant-statistic-content-value {
  font-size: 12px !important;
}
.withdraw-otp .ant-statistic-content {
  font-size: 12px !important;
}
.withdraw-payment .ant-form-item-label > label {
  color: $grey3-color !important;
}
.deposit-loas-upload {
  &.ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload.ant-upload-select,
  .ant-upload-wrapper.ant-upload-picture-circle-wrapper
    .ant-upload.ant-upload-select {
    width: 347px;
    height: 100%;
    min-height: 119px;
    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 8px;
    }
  }
}
